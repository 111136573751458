const state = function () {
	return {
		token: null,
		refreshToken: null,
		tokenRequestTime: null,
		verified: false,
		bloqueioDisciplinas: [],
		permiteVerModalTermos: false,
	};
};

export default state;
