<template>
	<div id="app">
		<Theme />
		<transition name="scale">
			<keep-alive>
				<router-view />
			</keep-alive>
		</transition>
	</div>
</template>

<script>
// import UIResponsiveView from '@components/UIResponsiveView';
import Theme from '@components/Theme';
import { getDynamicIeBySubDomain, changeAPIEnvironment } from "@commons/helpers"

const loadConfig = (ie) => {
	return require(`./ies/${ie}.config.json`);
}

const getConfig = () => {
	let config = null;
	const iesList = require('./ies/ies.list.json');

	try {
		let selectedIe = getDynamicIeBySubDomain(window.location.hostname, iesList);
		config = loadConfig(selectedIe);
	}catch(e){
		config = loadConfig('default');
	}

	//set global config IE
	return config;
}

export default {
	name: 'App',
	components: {
		// UIResponsiveView,
		Theme
	},
	beforeCreate() {
		let ieConfig = getConfig();
		let apiChanged = changeAPIEnvironment(window.location);

		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", ieConfig.favicon);

		if (apiChanged) {
			ieConfig.api_path = apiChanged;
		}

		this.$store.commit('setIE', ieConfig);
	},
}
</script>

<style lang="scss">
@import "@styles/styles";
</style>
