
import http from '@commons/http';

const actions = {
	getCurso: async (_, payload) => {
		const {
			curs_id,
			nens_id,
		} = payload;
		const response = await http.get(`/v1/inscricao/curso?curs_id=${curs_id}&nens_id=${nens_id}`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
	getEndereco: async (_, cep) => {
		const response = await http.get(`/v1/aluno/cep-address?cep=${cep}`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
	inscrever: async (_, payload) => {
		const canal = 'web';
		const {
			dados_pessoais: {
				cpf,
				email,
				name,
				phone,
				social_name,
			},
			endereco: {
				cida_id,
				esta_id,
				zipcode,
			},
			nens_id,
			curs_id,
		} = payload;
		const response = await http.post(`
			/v1/academico/inscricao?
				nens_id=${nens_id}
				&curs_id=${curs_id}
				&canal=${canal}
				&nome=${name}
				${social_name ? `&nome_social=${social_name}` : ''}
				&telefone=${phone}
				&cpf=${cpf}
				&email=${email}
				&esta_id=${esta_id}
				&cida_id=${cida_id}
				&cep=${zipcode}
		`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
	getPagamentos: async (_, payload) => {
		const {
			cupom,
			cand_id,
		} = payload;
		const response = await http.get(`/v1/financeiro/preco/checkout?cand_id=${cand_id}${cupom ? `&cupom=${cupom}`  : ''}`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
	getContrato: async (_, payload) => {
		const {
			cand_id,
		} = payload;
		const response = await http.post(`/v1/matricula/oferta?
			cand_id=${cand_id}
			&rg=RG000000001
			&data_nascimento=01/01/2000
			&sexo=F
		`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
	validaCupom: async (_, payload) => {
		const {
			cand_id,
			cupom,
		} = payload;
		const response = await http.get(`
			/v1/academico/codigo-promocional?
				cand_id=${cand_id}
				&cupom=${cupom}
		`);
		if (response.status === 200) {
			return response.data;
		}
			return null;
	},
};

export default actions;
