<script>
import Color from 'colorjs.io';

import {
	getDynamicIeBySubDomain
} from '@commons/helpers';

const parseColorGuide = function (data, type) {
	return Object.keys(data[type])
		.map(index => `--${ type }-${ index }: ${ data[type][index] }; `)
		.join('')
	;
};

export default {
	name: 'Theme',

	beforeCreate() {
		const style = document.createElement('style');
		const iesList = require('../ies/ies.list.json');
		const ie      = getDynamicIeBySubDomain(window.location.hostname, iesList);

		style.id = 'theme-color';

		const hasLegacy = typeof this.$store.state.ie.style.legacy !== 'undefined';

		const primaryLegacy = hasLegacy
			? this.$store.state.ie.style.legacy.colors.primary
			: this.$store.state.ie.style.colors.primary;

		const primaryLegacyTransparent = new Color(primaryLegacy);

		primaryLegacyTransparent.alpha = 0.25;

		style.innerHTML = `
:root,
:root .legacy-colors {
	--primary: ${ primaryLegacy };
	--primary-outline: ${ primaryLegacyTransparent.toString() };
	--primary-hover: ${ new Color(primaryLegacy).darken(0.3).toString() };
	--light-green: ${ new Color(primaryLegacy).lighten(0.2).toString() };
}
`;

		if (hasLegacy) {
			const [ primaryType, primaryIndex ] = this.$store.state.ie.style.colors.primary.split('.');

			const primary = this.$store.state.ie.style.colorGuide[primaryType][primaryIndex];

			const [ secondaryType, secondaryIndex ] = (this.$store.state.ie.style.colors.secondary || '').split('.');

			const secondary = secondaryType
				? this.$store.state.ie.style.colorGuide[secondaryType][secondaryIndex]
				: primary;

			const primaryScale = parseColorGuide(this.$store.state.ie.style.colorGuide, 'primary');

			const secondaryScale = typeof this.$store.state.ie.style.colorGuide.secondary !== 'undefined'
				? parseColorGuide(this.$store.state.ie.style.colorGuide, 'secondary')
				: '';

			style.innerHTML = style.innerHTML + `
:root {
	${ primaryScale }
	 ${ secondaryScale }
}
:root .new-colors {
	--primary: ${ primary };
	--secondary: ${ secondary };
}
`;
		}

		document.getElementsByTagName('head')[0].appendChild(style);
		
		if (ie === 'ebr') {
			console.log(ie)
			const stylePortal = document.createElement('style');
			stylePortal.lang = 'css';
			stylePortal.textContent = require('../ebradi/assets/scss/portal.css');
			document.getElementsByTagName('head')[0].appendChild(stylePortal);
		}
	},

	render() {
		return '';
	},
}
</script>
