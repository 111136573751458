import http from '@commons/http';
import { setRefreshToken } from '@commons/authenticate'

const actions = {
	async login({ commit, dispatch }, loginData) {
		const data = (await http.post('/v1/auth/Login', loginData)).data;

		commit('setToken', {
			token: data.token,
			refreshToken: data.refreshToken,
		});

		setRefreshToken(data.refreshToken);

		dispatch('setData', data);

		commit('setPermiteVerModalTermos', data.permite_ver_modal_termos);

		commit('setVerified', true);
	},
};

export default actions;
